import React, { useEffect, useState } from 'react'
import CustomerHeader from '../Header'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCities, searchCity } from '../../../redux/reducers/homeSlice';
import { Controller, useForm } from 'react-hook-form';
import CitySelect from '../../../components/SelectSearch';
import apiClient from '../../../shared/apiClient';
import toast from 'react-hot-toast';
import { getSessionItem } from '../../../shared/lsConfig';

export default function FinancialInfo() {
    const payload = useLocation().state;
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const lastData = sessionStorage.getItem('financial_info') ? JSON.parse(sessionStorage.getItem('financial_info')) : {}
    const { register, control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            ...lastData,
        },
        mode: 'onChange'
    });
    const dispatch = useDispatch();
    const { cities, searchCities } = useSelector(state => state.home)

    useEffect(() => {
        dispatch(getCities(1, 10))

    }, [])

    const handleSave = async (data) => {

        const formData = {
            ...data,
            VerificationDocument: [
                {
                    file: await convertToBase64(data.VerificationDocument[0]) ?? null,
                    fileName: "document"
                }
            ]
        }
        setLoading(true)
        apiClient.post('/Auth/VendorAccountRequestP2', formData)
            .then((res) => {
                toast.success(res.data?.message)
                navigate('/background-check')
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log('errr', err.response);
            });
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )


    const loadCities = async (inputValue, callback) => {
        if (inputValue) {
            dispatch(searchCity({
                searchText: inputValue,
                countryName: null,
                pageNumber: 1,
                pageSize: 10
            }))
        }
        const cityOptions = searchCities.length > 0 && searchCities.map(city => ({
            label: city.cityName,
            value: city.cityID,
        }));
        callback(cityOptions);
    };


    return (
        <>
            <CustomerHeader />
            <section className="py-5 title-styled main-vendor">
                <section className="py-5">
                    <div className="heading-bordered mb-4 pb-1">
                        <div className="container pt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h4>Financial Information</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSubmit(handleSave)}>

                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter first name"
                                            disabled={getSessionItem('name') ? getSessionItem('name').split(' ')[0] : false}
                                            {...register('FirstName')}
                                            value={getSessionItem('name') ? getSessionItem('name').split(' ')[0] : ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter last name"
                                            disabled={getSessionItem('name') ? getSessionItem('name').split(' ')[0] : ""}
                                            {...register('LastName')}
                                            value={getSessionItem('name') ? getSessionItem('name').split(' ')[1] : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Verify your identity</label>
                                        <div className="upload-container upload-container2 text-center">
                                            <label
                                                htmlFor="file-upload"
                                                className="upload-label upload-label2"
                                            >
                                                <span className="upload-icon upload-icon2 mb-3" />
                                                <span className="upload-caption upload-caption2 d-block mt-2">
                                                    Please upload a Driver's License or Valid Identification
                                                    photo.
                                                </span>
                                                <button
                                                    // type="submit"

                                                    onClick={(e) => e.preventDefault()}
                                                    className="btn btn-primary btn-1 w-autostyle mt-4"
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </label>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                accept="image/*"
                                                // className="upload-input"
                                                style={{ opacity: 0, marginBottom: '-20px' }}
                                                {...register('VerificationDocument', { required: 'Verification Document is required!' })}
                                            />
                                            {<p >{watch("VerificationDocument")?.length > 0 && watch("VerificationDocument")[0].name}</p>}

                                        </div>
                                        {errors.VerificationDocument && <p className='text-danger'>{errors.VerificationDocument.message}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Account Number</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your account number"
                                            {...register('AccountNumber', { required: 'Account Number is required!' })}
                                        />
                                        {errors.AccountNumber && <p className='text-danger'>{errors.AccountNumber.message}</p>}

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Account Title</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter account title"
                                            name='AccountTitle'
                                            {...register('AccountTitle', { required: 'Account Title is required!' })}
                                        />
                                        {errors.AccountTitle && <p className='text-danger'>{errors.AccountTitle.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Routing Number</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your routing number"
                                            name='RoutingNumber'
                                            {...register('RoutingNumber', { required: 'Routing Number is required!' })}
                                        />
                                        {errors.RoutingNumber && <p className='text-danger'>{errors.RoutingNumber.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Mailing Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter mailing address"
                                            name='Mailing'
                                            {...register('MailingAddress', { required: 'Mailing Address is required!' })}
                                        />
                                        {errors.MailingAddress && <p className='text-danger'>{errors.MailingAddress.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Zip Postal Code</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter zip postal code"
                                            {...register('ZipPostalCode', { required: 'Zip Postal Code is required!' })}
                                        />
                                        {errors.ZipPostalCode && <p className='text-danger'>{errors.ZipPostalCode.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">City</label>

                                        {/* <select
                                            className="form-select f-v"
                                            {...register("City", {
                                                required: "City is required!",
                                                validate: (value) => value !== "" || "City field is required!",
                                            })}

                                        >
                                            <option value="">Select City</option>
                                            {cities?.map((city, index) => <option key={index} value={city.cityID}>{city.cityName}</option>)}
                                        </select> */}
                                        <Controller
                                            name="CityToServe"
                                            control={control}
                                            rules={{ required: 'City is required' }}
                                            render={({ field }) => (
                                                <div className='form-control' style={{ padding: '0 0 4px 0', border: '1px solid #00cc66' }}>
                                                    <CitySelect
                                                        {...field}
                                                        loadOptions={loadCities}
                                                        handleChange={({ label, value }) => field.onChange(value)} // Update form state
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors.City && <p className='text-danger'>{errors.City.message}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 my-md-4 my-3">
                                    <div className="fv-outer">
                                        <label className="form-label fv-label">Permanent Address</label>
                                        <input
                                            type="text"
                                            className="form-control f-v"
                                            placeholder="Enter your permeant address"
                                            {...register('PermanentAddress', { required: 'Permanent Address is required!' })}
                                        />
                                        {errors.PermanentAddress && <p className='text-danger'>{errors.PermanentAddress.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center mt-5">
                                    <button
                                        className='btn btn-1 mx-2'
                                        style={{ background: '#fff', color: '#000' }}
                                        onClick={() => navigate('/vendor')}
                                    >Back</button>
                                    <button type="submit" className="btn btn-primary btn-1" >
                                        {loading ? loader : <>Submit <i className="fa fa-arrow-circle-right ms-2" /></>}
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}
