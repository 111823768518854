import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Customer from "./pages/customers/home";
import LoginPage from "./pages/customers/auths/login";
import ForgetPassword from "./pages/customers/auths/ForgetPassword";
import WelcomeScreen from "./pages/customers/auths/Welcome";
import Signup from "./pages/customers/auths/Signup";
import OTPVerification from "./pages/customers/auths/OTPVerification";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import About from "./pages/customers/about";
import BackgroundCheck from "./pages/customers/auths/BackgroundCheck";
import FinancialInfo from "./pages/customers/auths/FinancialInfo";
import UpdatePassword from "./pages/customers/auths/UpdatePassword";
import { Toaster } from "react-hot-toast";
import CustomSearch from "./pages/customers/home/CustomSearch";
import Vendor from './pages/customers/auths/Vendor'

function App() {

    useEffect(() => {
        const handleScroll = () => {
            const header = document.getElementById("header-sroll");
            if (header) {
                if (window.scrollY > 110) {
                    header.classList.add("header-color");
                } else {
                    header.classList.remove("header-color");
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter basename="/">
                <Routes>
                    <Route path="/" element={<Customer />} />
                    <Route path="/custom-search" element={<CustomSearch />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/welcome-screen" element={<WelcomeScreen />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    <Route path="/update-password" element={<UpdatePassword />} />
                    <Route path="/otp-verification" element={<OTPVerification />} />
                    <Route path="/background-check" element={<BackgroundCheck />} />
                    <Route path="/financial-info" element={<FinancialInfo />} />

                    <Route path="/vendor" element={<Vendor />} />

                    <Route path="/about" element={<About />} />
                </Routes>
                <Toaster position="top-right" />
            </BrowserRouter>
        </Provider>
    );
}

export default App;

