import React, { useEffect } from 'react'

export default function Seller({ vendors = [] }) {

    useEffect(() => {
        const $ = window.jQuery;

        const carousel = $('#reviews-carousel1');
        carousel.trigger('destroy.owl.carousel');
        carousel.owlCarousel({
            center: false,
            items: vendors.length > 10 ? 10 : vendors?.length,
            dots: true,
            loop: true,
            margin: 10,
            responsiveClass: true,
            nav: true,
            responsive: {
                0: {
                    items: 1,
                },
                1200: {
                    items: 2,
                },
            },
        });
    }, [vendors]);

    return (
        <section className="review-sec title-styled">
            <div className="container pb-5">
                <div className="row mb-4">
                    <div className="col-sm-12">
                        <h2>Top Rated Providers</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div
                            className="owl-carousel owl-theme cstm-theme-navs cstm-navs-center"
                            id="reviews-carousel1"
                        >

                            {vendors.slice(0, 10).map((vendor, index) => (
                                <div className="item" key={index}>
                                    <div className="review-box review-box d-flex flex-column flex-lg-row align-items-center">
                                        <div
                                            className="review-img"
                                        // style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                        >
                                            <img
                                                src={`https://stagingapis.withsabr.com/${vendor.profileURL}`}
                                                style={{ width: '100%', height: '100%' }}
                                            />
                                        </div>
                                        <div className="review-details">
                                            <h4>{`${vendor?.firstName} ${vendor?.lastName}`}</h4>
                                            <h4>
                                                <Rating rating={vendor?.averageRating} />
                                            </h4>
                                            {/* <h4>
                                                4.8 <i className="fa fa-star ms-1" />
                                                <i className="fa fa-star ms-1" />
                                                <i className="fa fa-star ms-1" />
                                                <i className="fa fa-star ms-1" />
                                                <i className="fa fa-star text-white ms-1" />
                                            </h4> */}
                                            <p>{vendor?.experienceYears} {vendor?.experienceYears > 1 ? "years" : "year"} experience</p>
                                            <button type="submit" className="btn btn-primary btn-1 mt-3">
                                                View Details <i className="fa fa-arrow-circle-right ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            ))}

                            {/* <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="review-box d-flex flex-column flex-lg-row align-items-center">
                                    <div
                                        className="review-img"
                                        style={{ backgroundImage: `url(/assets/img/t-1.png)` }}
                                    />
                                    <div className="review-details">
                                        <h4>Hannah | Belfast</h4>
                                        <h4>
                                            4.8 <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star ms-1" />
                                            <i className="fa fa-star text-white ms-1" />
                                        </h4>
                                        <p>8 years experience</p>
                                        <button type="submit" className="btn btn-primary btn-1 mt-3">
                                            View Details <i className="fa fa-arrow-circle-right ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const Rating = ({ rating }) => {
    // Ensure rating is a value between 0 and 5
    const filledStars = Math.floor(rating);
    const emptyStars = 5 - filledStars;

    return (
        <div>
            <span>{rating.toFixed(1)}</span>

            {[...Array(filledStars)].map((_, index) => (
                <i key={index} className="fa fa-star ms-1 text-warning" />
            ))}

            {[...Array(emptyStars)].map((_, index) => (
                <i key={index + filledStars} className="fa fa-star ms-1" style={{ color: '#fff' }} />
            ))}

        </div>
    );
};

