import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import apiClient from '../../../shared/apiClient';
import { setSessionItem, getSessionItem, deleteSessionItem } from '../../../shared/lsConfig';
import { useDispatch } from 'react-redux';
import { loginTrue } from '../../../redux/reducers/authSlice';
import toast from 'react-hot-toast';

export default function OTPVerification() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    const [error, setError] = useState(null);
    const [currentType, setCurrentType] = useState('email');
    const inputRefs = useRef([]);

    useEffect(() => {
        const type = getSessionItem('type') || 'email';
        const email = getSessionItem('email');
        const number = getSessionItem('number');
        const role = getSessionItem('userRole');

        if (!email || !number || !role) {
            navigate('/login');
        } else {
            setCurrentType(type);
        }
    }, [navigate]);

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && !e.target.value) {
            inputRefs.current[index - 1].focus();
        }
    };

    const resetInputs = () => {
        inputRefs.current.forEach(input => {
            if (input) input.value = '';
        });
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        const otp = Object.values(data).join('');
        if (!otp) return setError('Otp is required!');
        setError(null);
        setLoading(true);

        const route = currentType !== 'email' ? '/Auth/verifyTextOtp' : '/Auth/verifyEmailOtp';
        try {
            const res = await apiClient.post(route, {
                otp,
                email: getSessionItem('email'),
                phone: getSessionItem('number')
            });

            if (res.data) {
                if (currentType === 'email') {
                    setSessionItem('type', 'text');
                    setCurrentType('text');
                    setSuccessMsg('Email verified successfully. Proceed to phone verification.');
                } else {
                    deleteSessionItem('type');
                    setSessionItem('auth_token', res.data?.token);
                    setSessionItem('refresh_token', res.data?.refreshToken);
                    toast.success('Authenticated Successfully.');
                    dispatch(loginTrue());

                    if (getSessionItem('userRole') === 'vendor') {
                        navigate(`/vendor`);
                    } else {
                        navigate('/');
                    }
                }
                resetInputs();
            }
        } catch (err) {
            setError(err?.response?.data?.message || 'Verification failed.');
        } finally {
            setLoading(false);
        }
    };


    const maskNumber = (number = "") => {
        const numStr = number?.toString();
        if (numStr?.length <= 4) return numStr;
        const firstThree = numStr?.slice(0, 3);
        const lastOne = numStr?.slice(-1);
        const hashCount = numStr?.length - 4;

        return `${firstThree}${'*'.repeat(hashCount)}${lastOne}`;
    }
    const maskEmail = (email = "") => {
        const [username, domain] = email.split('@');
        if (username.length <= 2) return email;

        const maskedUsername = `${username.slice(0, 2)}${'*'.repeat(username.length - 2)}`;
        return `${maskedUsername}@${domain}`;
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('Text');

        if (pastedText.length <= inputRefs.current.length) {
            pastedText.split('').forEach((char, i) => {
                inputRefs.current[i].value = char;
                if (i < inputRefs.current.length - 1) {
                    inputRefs.current[i + 1].focus();
                }
            });
        }
    };

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );

    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>OTP Verification</h2>
                <p className="pt-4 mb-0 mb-md-2">
                    Enter OTP Code sent to {currentType === 'email' ? maskEmail(getSessionItem('email')) : maskNumber(getSessionItem('number'))}
                </p>
                <div className="py-3 px-lg-5">
                    <form onSubmit={handleVerifyOtp}>
                        <div className="mt-3 mb-4 pb-2 code-field d-flex gap-3 justify-content-center">
                            {Array(6).fill().map((_, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="form-control f-0 code-section blanked"
                                    maxLength={1}
                                    name={index}
                                    ref={el => inputRefs.current[index] = el}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onPaste={index === 0 ? handlePaste : null}
                                />
                            ))}
                        </div>
                        <p>
                            Didn’t receive OTP code?
                            <br />
                            <a href="#">Resend Code</a>
                        </p>
                        {error && <p className="text-danger">{error}</p>}
                        {successMsg && <p className="text-success">{successMsg}</p>}
                        <button
                            className="btn btn-primary btn-1 d-block w-100 my-4"
                            type="submit"
                        >
                            {loading ? loader : <>Verify &amp; Proceed</>}
                        </button>
                    </form>
                </div>
            </div>
        </AuthLayout>
    );
}
