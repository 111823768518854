import React, { useEffect } from "react";

const AppleLogin = () => {
    const loadAppleScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
            script.onload = () => resolve();
            script.onerror = () => reject(new Error("Failed to load Apple Sign-In script"));
            document.body.appendChild(script);
        });
    };

    const handleAppleSignIn = () => {
        if (!window.AppleID) {
            console.error("AppleID SDK not loaded yet.");
            return;
        }

        window.AppleID.auth
            .signIn()
            .then((response) => {
                const email = response?.user?.email;
                const firstName = response?.user?.name?.firstName;
                const lastName = response?.user?.name?.lastName;
                const idToken = response.authorization.id_token;

                fetch("https://stagingApis.withSabr.com/api/Auth/ThirdPartyLogin", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        Token: idToken,
                        provider: "Apple",
                        userRole: "Customer",
                    }),
                })
                    .then((res) => {
                        if (res.ok) {
                            console.log("User data successfully sent to the server.");
                        } else {
                            console.error("Failed to send user data to the server.");
                        }
                    })
                    .catch((error) => {
                        console.error("Error during data send:", error);
                    });
            })
            .catch((error) => {
                console.error("Error during sign-in:", error);
            });
    };

    useEffect(() => {
        loadAppleScript()
            .then(() => {
                window.AppleID.auth.init({
                    clientId: "SabrWithTesting",
                    scope: "name email",
                    redirectURI: "https://stagingApis.withSabr.com/api/auth/AppleCallBack",
                    state: "some_unique_state_value",
                    usePopup: true,
                });
            })
            .catch((error) => {
                console.error("Error loading Apple Sign-In SDK:", error);
            });
    }, []);

    // return <li style={{ marginTop: 0, display: 'flex', alignItems: 'center' }} role="button" onClick={handleAppleSignIn}>
    //     <i className="fa fa-apple" />
    // </li>
    return <button onClick={handleAppleSignIn}><i role='button' href="#" className="fa fa-apple" /></button>
};

export default AppleLogin;
