import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.css';
import { Button, Modal, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import apiClient from '../../shared/apiClient';
import toast from 'react-hot-toast';

const CalendarComponent = () => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [timeVisible, setTimeVisible] = useState(false);
    const [timeModalDate, setTimeModalDate] = useState(null);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false)

    const handleDateChange = (date) => {
        const dateString = new Date(date).toDateString();

        const existingTime = selectedDates.find(
            (selectedDate) => selectedDate.availabilityDate === new Date(date).toISOString()
        );

        if (existingTime) {
            const newSelectedDates = selectedDates.filter(
                (selectedDate) => selectedDate.availabilityDate !== new Date(date).toISOString()
            );
            setSelectedDates(newSelectedDates);
        } else {
            setTimeModalDate(dateString);
            setTimeVisible(true);
        }
    };

    function getFormattedTime(time) {
        const date = new Date(time);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }



    const handleTimeSave = () => {
        if (!timeFrom || !timeTo) {
            setErrorMessage("Both 'From' and 'To' times are required.");
            return;
        }

        setErrorMessage("");
        const newSelectedDates = [...selectedDates];
        const availability = {
            availabilityDate: new Date(timeModalDate).toISOString(),
            startTime: getFormattedTime(timeFrom),
            endTime: getFormattedTime(timeTo)
        };

        const dateIndex = newSelectedDates.findIndex(
            (selectedDate) => selectedDate.availabilityDate === availability.availabilityDate
        );

        if (dateIndex === -1) {
            newSelectedDates.push(availability);
        } else {
            newSelectedDates[dateIndex] = availability;
        }

        setSelectedDates(newSelectedDates);
        setTimeVisible(false);
        setTimeFrom(null)
        setTimeTo(null)
    };

    useEffect(() => {
        console.log('sssssssss', getFormattedTime(timeFrom), getFormattedTime(timeTo))
    }, [timeFrom, timeTo])

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const selected = selectedDates.find(
                (selectedDate) => selectedDate.availabilityDate === new Date(date).toISOString()
            );
            if (selected) {
                return 'react-calendar__tile--active';
            }
        }
        return '';
    };

    const handleTimeFromChange = (date) => {
        setTimeFrom(date);
    };

    const handleTimeToChange = (date) => {
        setTimeTo(date);
    };

    const handleUpdateAvailibility = () => {
        // console.log('sssssss', selectedDates)
        setLoading(true)
        apiClient.post('/Vendor/UpdateCustomAvailability', { availabilities: selectedDates }).then((res) => {
            toast.success(res.data?.message)
            setLoading(false)
        }).catch((err) => {
            toast.error(err.response?.data?.message)
            setLoading(false)
        })
    }

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )


    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Calendar
                    onChange={handleDateChange}
                    value={new Date()}
                    tileClassName={tileClassName}
                />
                <button
                    type="button"
                    class="btn btn-primary btn-1 "
                    style={{ position: 'absolute', top: '5px', right: '10px' }}
                    onClick={handleUpdateAvailibility}
                >
                    {loading ? loader : (<><i class="fa fa-plus me-1 fs-14"></i>Add</>)}
                </button>
            </div>

            <Modal show={timeVisible} onHide={() => setTimeVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Availability Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <div className="col-md-12">
                        <label htmlFor="name" className="form-label fv-label">
                            Set Time
                        </label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="fv-outer">
                                    <DatePicker
                                        className="form-control f-v calander-icon calander-icon-rgt"
                                        placeholderText="From: 11:00 AM"
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        selected={timeFrom}
                                        onChange={handleTimeFromChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-0 mt-3">
                                <div className="fv-outer">
                                    <DatePicker
                                        className="form-control f-v calander-icon calander-icon-rgt"
                                        placeholderText="To: 11:00 PM"
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        selected={timeTo}
                                        onChange={handleTimeToChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTimeVisible(false)}>
                        Cancel
                    </Button>
                    <button className='btn btn-success' onClick={handleTimeSave}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CalendarComponent;
