import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AuthLayout from './AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getRegister, loginTrue } from '../../../redux/reducers/authSlice';
import apiClient from '../../../shared/apiClient';
import axios from 'axios';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import toast from 'react-hot-toast';
import { setSessionItem } from '../../../shared/lsConfig';
import { useForm } from 'react-hook-form';
import AppleLogin from '../../../AppleLogin';

function Signup() {
    const dispatch = useDispatch()
    const auths = useSelector(state => state.auths)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [errors, setErrors] = useState()
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        number: ''
    })

    const { register, handleSubmit, watch, formState: { errors: err } } = useForm({
        mode: 'onChange'
    });


    const navigate = useNavigate();
    const prevState = useLocation().state


    useEffect(() => {
        if (!prevState?.userRole) navigate('/welcome-screen')
    }, [prevState])

    useEffect(() => {
        if (auths.isLogin) navigate(prevState?.userRole == "vendor" ? `/vendor?name=${userData.name}&email=${userData.email}&number=${userData.number}` : '/', { state: userData })
    }, [auths])


    const seperateName = (name = "") => {
        let data = { firstName: "", lastName: "" }
        const splitedName = name.split(' ')
        if (splitedName.length > 1) data = { firstName: splitedName[0], lastName: splitedName.slice(1)?.join(" ") }
        else data = { firstName: splitedName[0], lastName: "" }
        return data
    }

    const handleSignup = (data) => {

        const num = `${data?.phoneNumber}`
        const payload = { ...data, phoneNumber: num, ...seperateName(data?.fullName), userRole: prevState?.userRole };
        setLoading(true)
        apiClient.post('/Auth/register', payload)
            .then((res) => {
                setLoading(false)
                setSessionItem('number', payload?.phoneNumber)
                setSessionItem('email', payload?.email)
                setSessionItem('userRole', prevState?.userRole)
                setSessionItem('name', payload?.fullName)

                navigate('/otp-verification')
            }).catch(err => {
                if (err.response?.data?.errors) setErrors(err.response?.data?.errors)
                if (err.response.data?.message) setErrors(err.response?.data?.message)
                setLoading(false)
            })
    }


    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    const validation = (errors = []) => {
        if (errors.length > 0) {
            return errors.map((err, index) => <p key={index} className='text-danger'>{err}</p>)
        }
    }
    return (
        <AuthLayout>
            <div className="login-form-area px-3 px-lg-5">
                <h2>Create Account</h2>
                {/* <ul className="socials-2 my-3">

                    <GoogleOAuthProvider clientId="317913382060-1df0u8uphbnv99rg66k7kc9s2rtt4cbf.apps.googleusercontent.com">
                        <GoogleLoginButton userRole={prevState?.userRole == 'customer' ? "Customer" : "Vendor"} setUserData={setUserData} />
                    </GoogleOAuthProvider>


                    <li>
                        <AppleLogin />
                    </li>
                </ul> */}
                <div className='social-media-container'>
                    <GoogleOAuthProvider clientId="317913382060-1df0u8uphbnv99rg66k7kc9s2rtt4cbf.apps.googleusercontent.com">
                        <GoogleLoginButton />
                    </GoogleOAuthProvider>
                    <AppleLogin />
                </div>
                <p className="pt-4 mb-0 mb-md-2">
                    Or use your email for Registration!
                </p>
                <form className="py-3 px-lg-5" onSubmit={handleSubmit(handleSignup)}>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            className="form-control f-0"
                            placeholder="Full Name"
                            name='fullName'
                            {...register("fullName", { required: "FullName is required!" })}
                        />
                        {err.fullName && <p className='text-danger text-start'>{err.fullName.message}</p>}

                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="text"
                            className="form-control f-0"
                            placeholder="Username"
                            name='username'
                            {...register("username", { required: "Username is required!" })}
                        />
                        {err.username && <p className='text-danger text-start'>{err.username.message}</p>}
                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <input
                            type="email"
                            className="form-control f-0"
                            placeholder="Email"
                            name='email' {...register('email', {
                                required: 'Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'Invalid email address'
                                }
                            })}
                        />
                        {err.email && <p className='text-danger text-start'>{err.email.message}</p>}
                    </div>
                    <div className="my-4" style={{ textAlign: 'left' }}>
                        <div style={{ position: 'relative' }}>


                            {/* Phone Number Input */}
                            <input
                                type="text"
                                className="form-control f-0"
                                placeholder="Phone Number"
                                name="phoneNumber"
                                maxLength={10}
                                style={{
                                    position: 'relative',
                                    paddingLeft: '47px',
                                }}
                                {...register('phoneNumber', {
                                    required: 'Phone Number is required',
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Only number are allowed!'
                                    },
                                    minLength: {
                                        value: 10,
                                        message: 'Minimum length is 11 characters',
                                    },
                                })}
                            />
                            <p style={{
                                position: 'absolute',
                                top: '50%',
                                left: '30px',
                                transform: 'translateY(-50%)',
                                margin: 0,
                                fontSize: '18px',
                                color: '#808080',
                                pointerEvents: 'none'
                            }}

                            >
                                +1
                            </p>
                        </div>

                        {err.phoneNumber && <p className='text-danger text-start'>{err.phoneNumber.message}</p>}
                    </div>
                    <div className="my-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control f-0"
                            placeholder="Password"
                            {...register("password", {
                                required: "Password is required!",
                                pattern: {
                                    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]*$/,
                                    message:
                                        'Password must include at least one letter, one number, and one special character',
                                },
                                minLength: {
                                    value: 8,
                                    message: "Password must be at least 8 characters long"
                                }
                            })}
                        />
                        <i
                            className={showPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword1"
                            role='button'
                            onClick={() => setShowPassword(!showPassword)}
                        />
                        {err.password && <p className='text-danger text-start'>{err.password.message}</p>}
                    </div>
                    <div className="m4-4 icon-over" style={{ textAlign: 'left' }}>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control f-0"
                            id="confirmpass"
                            placeholder="Confirm Password"
                            name='confirm_password'
                            {...register("confirm_password", {
                                required: "Confirm Password field is required!",
                                validate: {
                                    match: value => value === watch('password') || "Passwords do not match"
                                }
                            })}
                        />
                        <i
                            className={showConfirmPassword ? "fa fa-eye-slash icon-over" : "fa fa-eye icon-over"}
                            id="togglePassword"
                            role='button'
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                        {err?.confirm_password && <p className="text-danger">{err.confirm_password.message}</p>}

                    </div>
                    <div className='mt-2' style={{ textAlign: 'left' }}>
                        {typeof errors !== "object" && <p className='text-danger'>{errors}</p>}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-1 d-block w-100 my-4"
                    >
                        {loading ? loader : "Register"}
                    </button>
                </form>
            </div>
        </AuthLayout>
    )
}


const GoogleLoginButton = ({ userRole, setUserData }) => {
    const dispatch = useDispatch();
    const handleLoginSuccess = async (tokenResponse, _) => {
        const token = tokenResponse.access_token;
        axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                const data = {
                    token: token,
                    email: res.data?.email,
                    provider: 'Google',
                    userRole: userRole
                }

                setUserData({
                    name: res.data?.name,
                    email: res.data?.email,
                    number: null
                })
                apiClient.post('/Auth/ThirdPartyLogin', data).then((res) => {
                    setSessionItem('auth_token', res.data?.token)
                    setSessionItem('refresh_token', res.data?.refreshToken)
                    setSessionItem('userRole', res.data?.userRole)
                    toast.success(res.data?.message)
                    dispatch(loginTrue())
                }).catch((err) => {
                    toast.error(err?.response?.data?.message || "Network Error!")
                })

            })
            .catch((err) => toast.error('Google Authentication Error'));

    }
    const handleLoginError = (error) => {
        console.log("Login Failed:", error);
    };

    const login = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: handleLoginError,
    });
    // return <button id='GoogleLoginBtn' onClick={login}  ><i className="fa fa-google" /></button>
    return <button onClick={login}><i role='button' className="fa fa-google" /></button>;
};


export default Signup
