// import React, { useEffect, useState } from 'react'
// import { Alert, Button, Modal } from 'react-bootstrap'
// import DatePicker from 'react-datepicker'

// const days = [
//     { id: 1, value: 'Sun' },
//     { id: 2, value: 'Mon' },
//     { id: 3, value: 'Tue' },
//     { id: 4, value: 'Wed' },
//     { id: 5, value: 'Thu' },
//     { id: 6, value: 'Fri' },
//     { id: 7, value: 'Sat' },
// ]
// export default function Recurring() {
//     const [selectedDays, setSelectedDays] = useState([])
//     const [timeVisible, setTimeVisible] = useState(false);
//     const [timeModalDate, setTimeModalDate] = useState(null);
//     const [timeFrom, setTimeFrom] = useState(null);
//     const [timeTo, setTimeTo] = useState(null);
//     const [errorMessage, setErrorMessage] = useState("");
//     const [payload, setPayload] = useState(false)

//     const handleTimeFromChange = (date) => {
//         setTimeFrom(date);
//     };

//     const handleTimeToChange = (date) => {
//         setTimeTo(date);
//     };

//     function getFormattedTime(time) {
//         const date = new Date(time);
//         let hours = date.getHours();
//         const minutes = date.getMinutes();
//         const seconds = date.getSeconds();
//         const formattedHours = hours.toString().padStart(2, '0');
//         const formattedMinutes = minutes.toString().padStart(2, '0');
//         const formattedSeconds = seconds.toString().padStart(2, '0');

//         return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
//     }

//     const handleTimeSave = () => {
//         if (!timeFrom || !timeTo) {
//             setErrorMessage("Both 'From' and 'To' times are required.");
//             return;
//         }

//         setErrorMessage("");
//         const newSelectedDates = [...selectedDates];
//         const availability = {
//             availabilityDate: new Date(timeModalDate).toISOString(),
//             startTime: getFormattedTime(timeFrom),
//             endTime: getFormattedTime(timeTo)
//         };

//         const dateIndex = newSelectedDates.findIndex(
//             (selectedDate) => selectedDate.availabilityDate === availability.availabilityDate
//         );

//         if (dateIndex === -1) {
//             newSelectedDates.push(availability);
//         } else {
//             newSelectedDates[dateIndex] = availability;
//         }

//         setSelectedDates(newSelectedDates);
//         setTimeVisible(false);
//         setTimeFrom(null)
//         setTimeTo(null)
//     };

//     return (
//         <div className="row align-items-center pt-4">
//             <div className="col-md-6 my-md-4 my-3">
//                 <div className="fv-outer">
//                     <label htmlFor="name" className="form-label fv-label d-block">
//                         Recurring
//                         <div className="form-check form-switch pull-right">
//                             <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 role="switch"
//                                 id="flexSwitchCheckChecked"
//                                 defaultChecked=""
//                             />
//                         </div>
//                     </label>
//                     <ul className="dayselection">
//                         {days.map((day, index) => (
//                             <li
//                                 key={index}
//                                 style={{ cursor: 'pointer' }}
//                                 className={selectedDays.find(d => d == day.id) && 'checkedday'}
//                                 onClick={() => setSelectedDays([...selectedDays, day.id])}
//                             >
//                                 <span>{day.value[0]}</span>{day.value}
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//             <div className="col-md-5 offset-md-1 my-md-4 my-3">
//                 <div className="fv-outer">
//                     <input
//                         type="text"
//                         className="form-control f-v calander-icon calander-icon-rgt"
//                         placeholder="Repeat until (Optional)"
//                     />
//                 </div>
//             </div>

//             <Modal show={timeVisible} onHide={() => setTimeVisible(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Availability Time</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

//                     <div className="col-md-12">
//                         <label htmlFor="name" className="form-label fv-label">
//                             Set Time
//                         </label>
//                         <div className="row">
//                             <div className="col-md-6">
//                                 <div className="fv-outer">
//                                     <DatePicker
//                                         className="form-control f-v calander-icon calander-icon-rgt"
//                                         placeholderText="From: 11:00 AM"
//                                         dateFormat="h:mm aa"
//                                         showTimeSelect
//                                         showTimeSelectOnly
//                                         timeIntervals={1}
//                                         timeCaption="Time"
//                                         selected={timeFrom}
//                                         onChange={handleTimeFromChange}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="col-md-6 mt-md-0 mt-3">
//                                 <div className="fv-outer">
//                                     <DatePicker
//                                         className="form-control f-v calander-icon calander-icon-rgt"
//                                         placeholderText="To: 11:00 PM"
//                                         dateFormat="h:mm aa"
//                                         showTimeSelect
//                                         showTimeSelectOnly
//                                         timeIntervals={1}
//                                         timeCaption="Time"
//                                         selected={timeTo}
//                                         onChange={handleTimeToChange}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setTimeVisible(false)}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={handleTimeSave}>
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>

//     )
// }


import React, { useState } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import apiClient from '../../shared/apiClient';
import toast from 'react-hot-toast';

const days = [
    { id: 1, value: 'Sun' },
    { id: 2, value: 'Mon' },
    { id: 3, value: 'Tue' },
    { id: 4, value: 'Wed' },
    { id: 5, value: 'Thu' },
    { id: 6, value: 'Fri' },
    { id: 7, value: 'Sat' },
];

export default function Recurring() {
    const [selectedDays, setSelectedDays] = useState([]);
    const [timeVisible, setTimeVisible] = useState(false);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentDay, setCurrentDay] = useState(null);
    const [availabilities, setAvailabilities] = useState([]);
    const [availabilityEndDate, setAvailabityEndDate] = useState()
    const [loading, setLoading] = useState(false)

    const handleDayClick = (day) => {
        const isExist = availabilities.find((a) => a.availabilityDay == day.id)
        if (!isExist) {
            setCurrentDay(day);
            setTimeVisible(true);
        } else {
            setAvailabilities(availabilities.filter((a) => a.availabilityDay !== day.id))
            setSelectedDays(selectedDays.filter(d => d !== day.id))
        }

    };

    const handleTimeSave = () => {
        if (!timeFrom || !timeTo) {
            setErrorMessage("Both 'From' and 'To' times are required.");
            return;
        }

        const formattedTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

        const newAvailability = {
            availabilityDay: currentDay.id,
            startTime: formattedTime(timeFrom),
            endTime: formattedTime(timeTo),
        };

        setAvailabilities((prev) => [...prev, newAvailability]);
        setSelectedDays((prev) => [...prev, currentDay.id]);
        setTimeVisible(false);
        setTimeFrom(null);
        setTimeTo(null);
        setErrorMessage('');
    };

    const handleUpdateRecurring = () => {
        const formData = {
            availabilities: availabilities,
            recurringAvailabilityEndDate: availabilityEndDate
        }
        setLoading(true)
        apiClient.post('/Vendor/UpdateRecurringAvailability', formData).then((res) => {
            // console.log('response', res.data)
            setLoading(false)
            toast.success(res.data?.message)
        }).catch((err) => {
            setLoading(false)
            toast.error("Failed!")
        })
    }

    const loader = (
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )

    return (
        <div className="row align-items-center pt-4">
            <div className="col-md-6 my-md-4 my-5">
                <div className="fv-outer">
                    <label htmlFor="name" className="form-label fv-label d-block">
                        Recurring
                        <div className="form-check form-switch pull-right">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                defaultChecked=""
                            />
                        </div>
                    </label>
                    <ul className="dayselection">
                        {days.map((day, index) => (
                            <li
                                key={index}
                                style={{ cursor: 'pointer' }}
                                className={selectedDays.includes(day.id) ? 'checkedday' : ''}
                                onClick={() => handleDayClick(day)}

                            >
                                <span>{day.value[0]}</span>{day.value}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-5 offset-md-1 my-md-4 my-3">

                <div className="fv-outer" >

                    <DatePicker
                        className="form-control f-v calander-icon calander-icon-rgt"
                        placeholderText='Repeat until (Optional)s'
                        selected={availabilityEndDate}
                        onChange={(date) => setAvailabityEndDate(date)}
                    />
                </div>

            </div>
            <div style={{ height: '50px', textAlign: 'right' }}>
                <button
                    type="button"
                    class="btn btn-primary btn-1"
                    onClick={handleUpdateRecurring}
                    style={{ marginTop: '-100px' }}
                >
                    {loading ? loader : (<><i class="fa fa-plus me-1 fs-14"></i>Add</>)}
                </button>
            </div>
            <Modal show={timeVisible} onHide={() => setTimeVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Time for {currentDay?.value}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <label htmlFor="name" className="form-label fv-label">
                            Set Time
                        </label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="fv-outer">
                                    <DatePicker
                                        className="form-control f-v calander-icon calander-icon-rgt"
                                        placeholderText="From: 11:00 AM"
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        selected={timeFrom}
                                        onChange={(date) => setTimeFrom(date)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-0 mt-3">
                                <div className="fv-outer">
                                    <DatePicker
                                        className="form-control f-v calander-icon calander-icon-rgt"
                                        placeholderText="To: 11:00 PM"
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        selected={timeTo}
                                        onChange={(date) => setTimeTo(date)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setTimeVisible(false)}>
                        Cancel
                    </Button>
                    <button className='btn btn-success' onClick={handleTimeSave}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}
